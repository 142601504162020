
import { Component, Prop, Mixins } from "vue-property-decorator";
import { formatDate } from "@/utils/formatters";
import { bus } from "@/main";
import { HasTimelineEntry } from "@/mixins/has-timeline-entry";

@Component({
    components: {
        UserImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class TimelineEntryCard extends Mixins(HasTimelineEntry) {
    @Prop({ default: false }) isLast?: boolean;
    @Prop({ default: "dark" }) theme?: "light" | "dark";
    @Prop({ default: false }) canEdit!: boolean;

    bus = bus;
}
